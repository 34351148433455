import React from 'react'
import './exploreFeatures.styles.scss'
import Star from '../../images/Star.svg'
import { Link } from 'gatsby'

const ExploreFeatures = ({ title, subtitle, text, path }) => {
	return (
		<div className="exploreFeatures">
			<h4>{title}</h4>
			<h2>{subtitle}</h2>
			{path === '/feature-detalis/' ? <p className="text">{text}</p> : null}
			<div className="scrollLinksSection">
				<div className="scrollLinksContainer">
					<Link to="#operation">Business Operations</Link>
					<Link to="#leasing">Leasing</Link>
					<Link to="#payment">Accounting & Payments</Link>
					<Link to="#marketing">Marketing</Link>
					<Link to="#business">Business Performance</Link>
				</div>
			</div>
			<div id="operation" className="featureCard">
				<div className="item">
					<img src={Star} alt="start icon" />
					<div>
						<h4>Resident Center</h4>
						<p>
							Utilize a resident website that is mobile-responsive to manage
							maintenance requests and payments online.
						</p>
					</div>
					{/* <button>More Details</button> */}
				</div>
				<div className="item">
					<img src={Star} alt="start icon" />
					<div>
						<h4>Owner Portal</h4>
						<p>
							Utilize a resident website that is mobile-responsive to manage
							maintenance requests and payments online.
						</p>
					</div>
					{/* <button>More Details</button> */}
				</div>
				<div className="item">
					<img src={Star} alt="start icon" />
					<div>
						<h4>Maintenance Request Tracking</h4>
						<p>
							Utilize a resident website that is mobile-responsive to manage
							maintenance requests and payments online.
						</p>
					</div>
					{/* <button>More Details</button> */}
				</div>
				<div className="item">
					<img src={Star} alt="start icon" />
					<div>
						<h4>Property Inspections</h4>
						<p>
							Utilize a resident website that is mobile-responsive to manage
							maintenance requests and payments online.
						</p>
					</div>
					{/* <button>More Details</button> */}
				</div>
				<div className="item">
					<img src={Star} alt="start icon" />
					<div>
						<h4>Violation Tracking</h4>
						<p>
							You can effortlessly keep track and report on your homeowners&#39;
							breaches from anywhere.
						</p>
					</div>
					{/* <button>More Details</button> */}
				</div>
				<div className="item">
					<img src={Star} alt="start icon" />
					<div>
						<h4>Board Communications</h4>
						<p>
							Reach out to board members, non-owner residents, and homeowners
							whenever you want and from wherever you are.
						</p>
					</div>
					{/* <button>More Details</button> */}
				</div>

				<div className="item">
					<img src={Star} alt="start icon" />
					<div>
						<h4>Company Financials</h4>
						<p>
							Utilize a resident website that is mobile-responsive to manage
							maintenance requests and payments online.
						</p>
					</div>
					{/* <button>More Details</button> */}
				</div>

				<div className="item">
					<img src={Star} alt="start icon" />
					<div>
						<h4>Retail Applications</h4>
						<p>
							Utilize a resident website that is mobile-responsive to manage
							maintenance requests and payments online.
						</p>
					</div>
					{/* <button>More Details</button> */}
				</div>
			</div>
		</div>
	)
}

export default ExploreFeatures
