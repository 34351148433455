import React from 'react'
import { graphql } from 'gatsby'
//import { Link } from "gatsby"
import Link from '../components/Link'

import Layout from '../components/layout'
import SEO from '../components/seo'
import '../css/realestate.scss'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import CustomButton from '../components/custom-button/custom-button.component.jsx'

import logo from '../images/logo.svg'

import kpi from '../images/Real-Estate-kpi.svg'
import idengager from '../images/Real-Estate-idengager.svg'
import idataworkers from '../images/Real-Estate-idataworkers.svg'
import iopsapps from '../images/Real-Estate-iopsapps.svg'
import unitsCatalog from '../images/units.svg'
import eCommerce from '../images/e-commerce-real-estate.svg'
import installmentsManagment from '../images/tenancy-management.svg'
import requestQuote from '../images/quots.svg'
import { Navbar } from '../components/navbar'
import { MinusOutlined } from '@ant-design/icons'
import ExploreFeatures from '../components/exploreFeatures/exploreFeatures'
import IMAGE1 from '../images/image-6.png'
import IMAGE2 from '../images/image-7.png'
import { StaticImage } from 'gatsby-plugin-image'

const FeaturesDetails = ({ data, path }) => (
	<Layout>
		<SEO title="Feature-details" description="providing a whole new way for real estate agents to work" />
		<div className="realestate-page">
			<Navbar />
			<div className="page-content realestate-content">
				<section className="products-content" id="products-content">
					<div className="features-details-page">
						<ExploreFeatures
							title={'Features'}
							subtitle={'Many Features for you !'}
							text={
								'Instead of learning how to design a website, attract residents and get new customers.'
							}
							path={path}
						/>
					</div>
					<div className="products-container">
						<div className="product-wrapper">
							<div className="product-parent">
								<div className="product-details">
									<h3 className="title">
										Give your business the attention it deserves
									</h3>
									<p className="subtitle">
										Instead of learning how to design a website,
										attract residents and get new customers.
									</p>
									<ul>
										<li>
											<MinusOutlined />
											<p>
												Create a free property management
												website to promote your business,
												showcase available properties, and
												highlight the services you provide.
											</p>
										</li>
										<li>
											<MinusOutlined />
											<p>
												Using All Property Management, you
												may reach out to property owners in
												your region and anywhere else you
												wish to expand.
											</p>
										</li>
									</ul>
									<CustomButton className="link-wrapper">
										<Link
											className="product-btn"
											data-track-location="realestate"
											to="/pricing"
										>
											Request a Demo
										</Link>
									</CustomButton>
								</div>
								<div className="product-img ">
									<img
										src={IMAGE2}
										className="item-img borderd"
										alt="iopsapps"
									/>
								</div>
							</div>
						</div>
						<div className="product-wrapper">
							<div className="product-parent reverse-parent">
								<div className="product-details">
									<h4>BUSINESS PERFORMANCE</h4>
									<h3 className="title">
										Make informed decisions
										<br /> (right away)
									</h3>
									<p className="subtitle">
										Make the best decisions in real time by having all
										of your company&#39;s activity monitored from one
										location.
									</p>
									<ul>
										<li>
											<MinusOutlined />
											<p>
												A complete suite of reporting will
												help you comprehend the performance
												of your entire portfolio. Analytics
												&amp; Insights can help you
												strategically manage your
												organization.
											</p>
										</li>
										<li>
											<MinusOutlined />
											<p>
												Using Open API to create custom
												integrations will increase the
												efficiency of data workflow.
											</p>
										</li>
									</ul>
									<CustomButton className="link-wrapper">
										<Link
											className="product-btn"
											data-track-location="realestate"
											to="/pricing"
										>
											Request a Demo
										</Link>
									</CustomButton>
								</div>
								<div className="product-img">
									<StaticImage
										src="../images/image-6.png"
										className="item-img borderdReverse"
										alt="iopsapps"
									/>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<div className="footer-section">
				<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
			</div>
		</div>
	</Layout>
)
export const pageQuery = graphql`
	query FeaturesQuery {
		Header: allMdx(filter: { frontmatter: { title: { eq: "Header" } } }) {
			nodes {
				body
			}
		}
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`
export default FeaturesDetails
